<template>
  <div class="card">
    <h2 class="card-title">Listado de Notificaciones</h2>
    <DataTable
        :show-filter="true"
        :header="headers"
        :body="body"
        :actionButton="actionButton"
        :endpoint="NotificacionesServices.api.getAll"
        @rowClicked="verNotificacion"
    />
  </div>
</template>
<script>
import { NotificacionesServices } from "../services/NotificacionesServices";
import DataTable from "@/components/elements/DataTable.vue";

export default {
  name: "ListarNotificacionesAdmin",
  computed: {
    NotificacionesServices() {
      return NotificacionesServices
    }
  },
  components: {DataTable },
  data() {
    return {
      headers: NotificacionesServices.headers(),
      body: NotificacionesServices.body(),
      actionButton: {
        text: "Nueva notificacion",
        action: () => {
          this.$router.push({ name: "CrearNotificacion" });
        },
      },
    };
  },
  methods: {
    verNotificacion(notificacion) {
      this.$router.push({ name: "VerNotificacion", params: { id: notificacion.id } });
    },
  },
};
</script>
